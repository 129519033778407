/*------------------------------------------------------------------
 * Website Name: Astradevlabs
 * App URI: http://www.astradevlabs.com
 * Author: astradevlabs
 * Author URI: http://www.astradevlabs.com
-------------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700");

@import url("../fonts/hustbee.css");
/* -----------------------------------
    1 - General Styles
------------------------------------*/
*, body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
}

/* custom container */
.container-ex {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.rtl-row > div[class^="col-"] {
    float: right;
}

.row.custom-padding {
    margin-left: -35px;
    margin-right: -35px;
}

.row.custom-padding > div[class^="col-"] {
    padding-left: 35px;
    padding-right: 35px;
}

@media (max-width: 1200px) {
    .row.custom-padding {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row.custom-padding > div[class^="col-"] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 480px) {
    .container-ex {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .container-ex {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container-ex {
        max-width: 960px;
    }

    .row.same-height {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
    }

    .row.same-height.rtl-row {
        flex-direction: row-reverse;
    }
}

@media (min-width: 1200px) {
    .container-ex {
        max-width: 1330px;
    }
}

.badge {
    border-radius: 2px;
    padding: 3px 6px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
}

.badge.badge-primary {
    background-color: #007bff;
}

.badge.badge-secondary {
    background-color: #6c757d;
}

.badge.badge-success {
    background-color: #28a745;
}

.badge.badge-danger {
    background-color: #ff2400;
}

.badge.badge-warning {
    background-color: #ffc107;
}

.badge.badge-info {
    background-color: #58bafc;
}

.badge.badge-light {
    background-color: #f8f9fa;
}

.badge.badge-dark {
    background-color: #406177;
}

.hbtn {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    padding: 8px 24px;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    border-radius: 25px;
    border: 0;
    outline: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.hbtn.hbtn-lg {
    padding: 10px 22px;
    font-size: 18px;
}

.hbtn.hbtn-primary {
    background-color: #ffc600;
    color: #0c090d;
}

.hbtn.hbtn-primary:hover, .hbtn.hbtn-primary:focus {
    text-decoration: none;
    background-color: #e6b200;
}

.hbtn.hbtn-default {
    background-color: #333c4e;
    color: #adadad;
}

.hbtn.hbtn-default:hover, .hbtn.hbtn-default:focus {
    color: #3b4048;
    text-decoration: none;
    background-color: #ffc600;
}

.hbtn.hbtn-blue {
    background-color: #5993fa;
    color: #fff;
}

.hbtn.hbtn-blue:hover, .hbtn.hbtn-blue:focus {
    text-decoration: none;
    background-color: #4083f9;
}

.light-header .hbtn.hbtn-primary {
    background-color: #3872C7;
    color: #fff;
}

.light-header .hbtn.hbtn-primary:hover, .light-header .hbtn.hbtn-primary:focus {
    background-color: #3267b3;
}

label {
    font-size: 16px;
    font-weight: 400;
    color: #3b4155;
    margin-bottom: 8px;
}

.form-control {
    border: 1px solid #dce2ea;
    padding: 12px 24px;
    border-radius: 5px;
    font-size: 16px;
    color: #3b4155;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  /*margin-bottom: 15px;*/
}

.form-control:hover, .form-control:focus {
    border-color: #bcc8d7;
    color: #262a37;
    -webkit-box-shadow: none;
    box-shadow: none;
}

textarea {
    resize: none;
    height: 200px;
}

select.form-control {
    height: inherit !important;
}

.select-holder {
    position: relative;
    margin-bottom: 15px;
}

.select-holder select.form-control {
    width: 100%;
    margin-bottom: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select-holder:after {
    position: absolute;
    font-family: "hustbee" !important;
    content: "\62";
    top: 13px;
    right: 15px;
    pointer-events: none;
}

.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 15px;
}

.form-row > .col:first-child, .form-row > [class*="col-"]:first-child {
    padding-left: 0;
}

.form-row > .col:last-child, .form-row > [class*="col-"]:last-child {
    padding-right: 0;
}

.login-register-form .form-holder .form-row > .col, .login-register-form .form-holder .form-row > [class*="col-"] {
    margin-bottom: 0;
}

.submit-holder {
    text-align: right;
}

.form-holder input[type="checkbox"], .form-holder input[type="radio"] {
    width: auto;
}

.form-holder input[type="checkbox"]:not(:checked), .form-holder input[type="checkbox"]:checked, .form-holder input[type="radio"]:not(:checked), .form-holder input[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}

.form-holder input[type="checkbox"]:not(:checked) + label, .form-holder input[type="checkbox"]:checked + label, .form-holder input[type="radio"]:not(:checked) + label, .form-holder input[type="radio"]:checked + label {
    position: relative;
    padding-left: 21px;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: #8490AA;
    margin-right: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-holder input[type="checkbox"]:checked + label, .form-holder input[type="radio"]:checked + label {
    color: #5a667c;
}

.form-holder input[type="checkbox"]:checked + label:before, .form-holder input[type="radio"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 13px;
    height: 13px;
    background: #384365;
    border-radius: 2px;
    border: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.form-holder input[type="checkbox"]:checked + label:hover:before, .form-holder input[type="checkbox"]:checked + label:focus:before, .form-holder input[type="radio"]:checked + label:hover:before, .form-holder input[type="radio"]:checked + label:focus:before {
    background: #262d44;
}

.form-holder input[type="checkbox"]:not(:checked) + label:before, .form-holder input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 12px;
    height: 12px;
    background: #c8ccdb;
    border-radius: 2px;
    border: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.form-holder input[type="checkbox"]:not(:checked) + label:after, .form-holder input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.form-holder input[type="checkbox"]:not(:checked) + label:hover:before, .form-holder input[type="checkbox"]:not(:checked) + label:focus:before, .form-holder input[type="radio"]:not(:checked) + label:hover:before, .form-holder input[type="radio"]:not(:checked) + label:focus:before {
    background: #a9afc7;
}

.form-holder input[type="checkbox"]:not(:checked) + label:after, .form-holder input[type="checkbox"]:checked + label:after {
    content: "\64";
    font-family: "hustbee";
    position: absolute;
    top: 0.5rem;
    left: 0.19rem;
    font-size: 0.8rem;
    color: #ffffff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.form-holder input[type="radio"]:not(:checked) + label:after, .form-holder input[type="radio"]:checked + label:after {
    content: "";
    position: absolute;
    top: 7px;
    left: 4px;
    width: 5px;
    height: 5px;
    border-radius: 20px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.form-holder input[type="radio"]:not(:checked) + label:before {
    border-radius: 10px;
}

.form-holder input[type="radio"]:checked + label:before {
    border-radius: 10px;
}

.form-holder label input[type="checkbox"]:not(:checked), .form-holder label input[type="checkbox"]:checked, .form-holder label input[type="radio"]:not(:checked), .form-holder label input[type="radio"]:checked {
    position: inherit !important;
    left: inherit !important;
}

body.fullpage {
    width: 100%;
    height: 100%;
    background-color: #111a2b;
}

.login-register-form {
    text-align: center;
    padding: 150px 0;
}

.login-register-form .form-holder {
    display: inline-block;
    width: 100%;
    max-width: 472px;
    text-align: left;
}

.login-register-form .form-holder .site-brand img {
    width: 100%;
    max-width: 122px;
}

.login-register-form .form-holder .form-title {
    text-align: right;
    font-size: 18px;
    color: #5288e8;
    border-radius: 20px;
    margin-bottom: 5px;
}

.login-register-form .form-holder .form-title img {
    width: 11px;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 8px;
}

.login-register-form .form-holder .form-head {
    padding-bottom: 75px;
}

.login-register-form .form-holder .form-links {
    text-align: center;
    font-size: 18px;
    color: #4c5465;
    padding-bottom: 55px;
}

.login-register-form .form-holder .form-links .link-to {
    font-size: 18px;
    color: #676e7b;
    text-decoration: none;
}

.login-register-form .form-holder .form-links .link-to:hover, .login-register-form .form-holder .form-links .link-to:focus {
    color: #737a89;
}

.login-register-form .form-holder .form-links .link-to.active {
    color: #5288e8;
}

.login-register-form .form-holder .form-links .link-to.active:hover, .login-register-form .form-holder .form-links .link-to.active:focus {
    color: #6997eb;
}

.login-register-form .form-holder label {
    font-weight: 400;
    font-size: 16px;
    color: #6a81ac;
}

.login-register-form .form-holder .form-control, .login-register-form .form-holder .field {
    color: #3b4155;
    border: 0;
    margin-bottom: 35px;
    height: inherit;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.login-register-form .form-holder .form-control:hover, .login-register-form .form-holder .form-control:focus, .login-register-form .form-holder .field:hover, .login-register-form .form-holder .field:focus {
    color: #5288e8;
    -webkit-box-shadow: inset 0 0 0 0.15rem #5288e8;
    box-shadow: inset 0 0 0 0.15rem #5288e8;
}

.login-register-form .form-holder a {
    font-size: 14px;
    color: #65728b;
}

.login-register-form .form-holder .forget-link {
    margin-bottom: -20px;
    vertical-align: middle;
    display: inline-block;
}

.login-register-form .form-holder .submit-holder [type="submit"] {
    border: 0;
    border-radius: 50px;
    background-color: #374154;
    font-weight: 500;
    font-size: 17px;
    color: #fff;
    outline: none;
    cursor: pointer;
    padding: 10px 34px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.login-register-form .form-holder .submit-holder [type="submit"]:hover, .login-register-form .form-holder .submit-holder [type="submit"]:focus {
    background-color: #2d3545;
}








/*------------------------------------------------------------------
	7 - Domain Search Section Styles
-------------------------------------------------------------------*/
.domain-search-holder {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #5993fa;
    overflow: hidden;
    z-index: 1;
}

.domain-search-holder:before {
    position: absolute;
    display: inline-block;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/cloud-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    opacity: 0.17;
    z-index: -1;
}

.domain-search-holder .title {
    font-size: 27px;
    font-weight: 700;
    color: #ffffff;
    padding: 6px 0;
    line-height: 30px;
}

.domain-search-holder .domain-input {
    font-size: 19px;
    font-weight: 700;
    color: #3d5e98;
    border: 0;
    width: 100%;
    padding: 12px 34px;
    border-radius: 100px;
    outline: 0;
    -webkit-box-shadow: 0 14px 12px rgba(31, 27, 90, 0.1);
    box-shadow: 0 14px 12px rgba(31, 27, 90, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.domain-search-holder .domain-input:hover, .domain-search-holder .domain-input:focus {
    -webkit-box-shadow: 0 14px 16px rgba(31, 27, 90, 0.15);
    box-shadow: 0 14px 16px rgba(31, 27, 90, 0.15);
}

.domain-search-holder button {
    font-size: 19px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    width: 100%;
    padding: 12px 22px;
    border-radius: 100px;
    background-color: #3d5e98;
    outline: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.domain-search-holder button:hover, .domain-search-holder button:focus {
    background-color: #365386;
}

.domain-search-holder .domain-input-holder {
    margin-top: 55px;
}

.domain-search-holder .domain-input-holder .domain-input {
    display: inline-block;
    width: 72%;
}

.domain-search-holder .domain-input-holder button {
    display: inline-block;
    width: 23%;
    margin-left: 15px;
}

.domain-search-holder .img-holder img {
    width: 170%;
    max-width: 600px;
    margin-top: -78px;
    margin-bottom: -112px;
}

/*------------------------------------------------------------------
	8 - Support Links Section Styles
-------------------------------------------------------------------*/
.support-links {
    background-color: #eaf2fe;
}

.support-links > .row .hylink-holder {
    padding-top: 80px;
    padding-bottom: 80px;
}

.support-links > .row .hylink-holder:first-child {
    background-color: #eaf2fe;
    text-align: right;
    padding-right: 80px;
    padding-left: 40px;
}

.support-links > .row .hylink-holder:last-child {
    background-color: #f4f8fe;
    text-align: left;
    padding-left: 80px;
    padding-left: 40px;
}

.hylink-box {
    position: relative;
    display: inline-block;
    text-align: left;
    width: 100%;
    max-width: 490px;
    padding: 15px 15px 15px 135px;
}

.hylink-box .icon {
    position: absolute;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 106px;
    height: 106px;
    background-color: #ffffff;
    border-radius: 16px;
    text-align: center;
    -webkit-box-shadow: 0 8px 12px rgba(31, 27, 90, 0.08);
    box-shadow: 0 8px 12px rgba(31, 27, 90, 0.08);
}

.hylink-box .icon img {
    width: 57px;
}

.hylink-box .link {
    font-size: 19px;
    font-weight: 700;
    color: #3d5e98;
    text-decoration: none;
    outline: none;
    display: inline-block;
    margin-bottom: 6px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.hylink-box .link:after {
    font-family: "hustbee" !important;
    content: "\63";
    color: #3d5e98;
    margin-left: 5px;
    vertical-align: middle;
    font-weight: 400;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.hylink-box .link:hover, .hylink-box .link:focus {
    color: #4469aa;
}

.hylink-box .link:hover:after, .hylink-box .link:focus:after {
    margin-left: 10px;
}

.hylink-box .text {
    font-size: 16px;
    font-weight: 300;
    color: #62647b;
    line-height: 21px;
}

/*------------------------------------------------------------------
	9 - Services Section Styles
-------------------------------------------------------------------*/
.services {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.services .row-title {
    font-size: 27px;
    font-weight: 700;
    color: #4d5666;
    margin-bottom: 10px;
}

.services .row-subtitle {
    font-size: 18px;
    font-weight: 300;
    color: #090a19;
    display: inline-block;
    width: 100%;
    max-width: 830px;
    margin-bottom: 40px;
}

.service-box {
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    padding: 62px 20px;
    margin: 35px 0;
    width: 100%;
  /*max-width: 288px;*/
    background-color: #fff;
    z-index: 1;
    outline: none;
    cursor: pointer;
    -webkit-box-shadow: 0 0 0 1px #82a9ff;
    box-shadow: 0 0 0 1px #82a9ff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.service-box .title {
    font-size: 23px;
    font-weight: 300;
    color: #3375e7;
    margin-bottom: 15px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.service-box .icon {
    display: inline-block;
    background-color: #ecf0f6;
    width: 94px;
    height: 94px;
    border-radius: 100px;
    padding-top: 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.service-box .icon img {
    width: 60px;
}

.service-box:hover, .service-box:focus {
    background-color: #4d5666;
    text-decoration: none;
}

.service-box:hover .title, .service-box:focus .title {
    font-weight: 500;
    color: #fff;
}

.service-box:hover .icon, .service-box:focus .icon {
    background-color: rgba(236, 240, 246, 0.24);
}

/*------------------------------------------------------------------
	10 - Blog Section Styles
-------------------------------------------------------------------*/
.blog {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
}

.post-box {
    display: inline-block;
    width: 100%;
    max-width: 1070px;
    margin-bottom: 80px;
}

.post-box .post-photo {
    margin-bottom: 30px;
}

.post-box .post-photo img {
    width: 100%;
}

.post-box .post-title {
    font-size: 27px;
    font-weight: 700;
    color: #4d5666;
    margin-bottom: 18px;
}

.post-box .post-summary {
    display: inline-block;
    width: 100%;
    max-width: 830px;
    font-size: 18px;
    font-weight: 300;
    color: #090a19;
    margin-bottom: 25px;
}

.post-box .post-content {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    color: #090a19;
    margin-bottom: 25px;
}

.post-box .post-content p {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    color: #090a19;
    margin-bottom: 25px;
}

.paging {
    text-align: center;
}

.paging .pagination {
    justify-content: center;
}

.pagination .page-item .page-link {
    margin: 0px 4px;
    line-height: 32px;
    color: #91a0bd;
    padding: 0;
    background-color: #eaebed;
    border: 0;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.pagination .page-item .page-link.prev, .pagination .page-item .page-link.next {
    line-height: 34px;
}

.pagination .page-item .page-link.prev i.hstb {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: 13px;
    margin-left: -4px;
}

.pagination .page-item .page-link.next i.hstb {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    font-size: 13px;
    margin-right: -4px;
}

.pagination .page-item .page-link:hover, .pagination .page-item .page-link:focus {
    color: #8192b3;
    background-color: #dcdee1;
}

.pagination .page-item.active .page-link {
    color: #111a2b;
    background-color: #ffc600;
}

/*------------------------------------------------------------------
	11 - Support Details Section Styles
-------------------------------------------------------------------*/
.support-details {
    background-color: #94a5c3;
}

.support-details > .container > .row > [class^="col"] {
    padding: 0;
}

.support-box {
    text-align: left;
    padding: 80px 60px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.support-box .icon {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    height: 106px;
    background-color: #ffffff;
    border-radius: 16px;
    text-align: center;
    margin-bottom: 35px;
    -webkit-box-shadow: 0 8px 12px rgba(31, 27, 90, 0.08);
    box-shadow: 0 8px 12px rgba(31, 27, 90, 0.08);
}

.support-box .icon i {
    font-size: 50px;
}

.support-box .icon.grey i {
    color: #6e7c96;
}

.support-box .icon.green i {
    color: #64cf68;
}

.support-box .icon.blue i {
    color: #2a74e3;
}

.support-box .icon.purple i {
    color: #ab70f4;
}

.support-box .title {
    font-size: 19px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 7px;
}

.support-box .details a {
    display: inline-block;
    width: 100%;
    color: #d8e4fa;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.support-box .details a:hover, .support-box .details a:focus {
    color: #c1d4f7;
}

/*------------------------------------------------------------------
	12 - Questions Section Styles
-------------------------------------------------------------------*/
.questions {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #c7c8cc;
}

.questions .row-title {
    font-size: 19px;
    font-weight: 700;
    color: #3d5e98;
    margin-bottom: 40px;
}

.questions .cards-holder {
    display: inline-block;
    width: 100%;
    /*max-width: 994px;*/
    text-align: left;
}

.question-card {
    text-align: left;
    border: 0;
    margin-bottom: 20px;
}

.question-card .card-header {
    border: 0;
    background: none;
    padding: 0;
}

.question-card .card-header button {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: 19px;
    font-weight: 700;
    color: #2a74e3;
    background-color: #fff;
    border: 1px solid #82a9ff;
    padding: 12px 64px 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.question-card .card-header button:after {
    position: absolute;
    font-family: "hustbee" !important;
    content: "\62";
    top: 5px;
    right: 22px;
    font-size: 28px;
    font-weight: 300;
    color: #ffc600;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.question-card .card-header button:hover, .question-card .card-header button:focus {
    border-color: #3674ff;
}

.question-card .card-header button.collapsed {
    color: #152c53;
    border-color: #dce2ea;
}

.question-card .card-header button.collapsed:after {
    color: #94a5c3;
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
}

.question-card .card-header button.collapsed:hover, .question-card .card-header button.collapsed:focus {
    border-color: #acbacd;
}

.question-card .card-body {
    font-size: 18px;
    font-weight: 300;
    color: #090a19;
    padding: 22px 20px;
}

/*------------------------------------------------------------------
	13 - Contact Section Styles
-------------------------------------------------------------------*/
.contact-us {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.contact-us .row-title {
    font-size: 19px;
    font-weight: 700;
    color: #3d5e98;
    margin-bottom: 60px;
}

.contact-us .form-holder {
    display: inline-block;
    width: 100%;
    max-width: 994px;
    text-align: left;
}

.contact-us .form-holder .form-control {
    height: inherit;
}

.contact-us .form-holder textarea {
    min-height: 200px;
}

.contact-us .address-details {
    text-align: left;
    padding-left: 22px;
}

.contact-us .address-details .title {
    font-size: 16px;
    font-weight: 400;
    color: #3b4155;
    margin-bottom: 22px;
}

.contact-us .address-details h4 {
    font-size: 15px;
    font-weight: 400;
    color: #2b74e3;
    margin-bottom: 8px;
}

.contact-us .address-details p {
    font-size: 15px;
    font-weight: 300;
    color: #62647b;
    margin-bottom: 29px;
    line-height: 24px;
}

/*------------------------------------------------------------------
	14 - Domain Pricing Section Styles
-------------------------------------------------------------------*/
.domain-pricing {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.domain-pricing .info-img {
    margin-bottom: 40px;
}

.domain-pricing .info-img img {
    width: 100%;
    max-width: 797px;
}

.domain-pricing .row-title {
    font-size: 27px;
    font-weight: 700;
    color: #4d5666;
}

.hstb-pricing-table-holder {
    margin-top: 40px;
}

.hstb-pricing-table-holder .hstb-table {
    text-align: center;
    min-width: 940px;
}

.hstb-pricing-table-holder .hstb-table .row {
    position: relative;
    background-color: #f6f6f9;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.08);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-left: 1px solid #d3d3dd;
    border-right: 1px solid #d3d3dd;
    margin: 0;
}

.hstb-pricing-table-holder .hstb-table .row.thead {
    border-top: 1px solid #d3d3dd;
    border-bottom: 1px solid #d3d3dd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fcfcfd;
}

.hstb-pricing-table-holder .hstb-table .row:last-child {
    border-bottom: 1px solid #d3d3dd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.hstb-pricing-table-holder .hstb-table .row .th {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #3d5e98;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 27px 10px;
    border-right: 1px solid #d3d3dd;
}

.hstb-pricing-table-holder .hstb-table .row .th:last-child {
    border-right: 0px solid #d3d3dd;
}

.hstb-pricing-table-holder .hstb-table .row .td {
    text-align: center;
    font-size: 15px;
    color: #6d7b88;
    padding: 27px 10px;
    font-weight: 300;
    text-transform: uppercase;
    border-top: 0;
    border-right: 1px solid #d3d3dd;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.hstb-pricing-table-holder .hstb-table .row .td:first-child {
    font-weight: 400;
    color: #4f5459;
}

.hstb-pricing-table-holder .hstb-table .row .td:last-child {
    border-right: 0px solid #d3d3dd;
}

.hstb-pricing-table-holder .hstb-table .row .td .register-button {
    border-radius: 30px;
    padding: 10px 24px;
    background-color: #3d5e98;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.hstb-pricing-table-holder .hstb-table .row .td .register-button:hover, .hstb-pricing-table-holder .hstb-table .row .td .register-button:focus {
    background-color: #365386;
}

.hstb-pricing-table-holder .hstb-table .row.trow:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 64px;
    top: 50%;
    margin-top: -32px;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 50px;
    opacity: 0;
    -webkit-box-shadow: 0 0 0 0.1rem #5993fa;
    box-shadow: 0 0 0 0.1rem #5993fa;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: scaleX(0.98) scaleY(0.9);
    -moz-transform: scaleX(0.98) scaleY(0.9);
    -ms-transform: scaleX(0.98) scaleY(0.9);
    transform: scaleX(0.98) scaleY(0.9);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 0;
}

.hstb-pricing-table-holder .hstb-table .row.trow:nth-of-type(odd) {
    background-color: #fcfcfd;
}

.hstb-pricing-table-holder .hstb-table .row.trow:hover, .hstb-pricing-table-holder .hstb-table .row.trow:focus {
    z-index: 100;
}

.hstb-pricing-table-holder .hstb-table .row.trow:hover:before, .hstb-pricing-table-holder .hstb-table .row.trow:focus:before {
    -webkit-transform: scaleX(0.985) scaleY(1);
    -moz-transform: scaleX(0.985) scaleY(1);
    -ms-transform: scaleX(0.985) scaleY(1);
    transform: scaleX(0.985) scaleY(1);
    background-color: #ffffff;
    opacity: 1;
}

.hstb-pricing-table-holder .hstb-table .row.trow:hover .td, .hstb-pricing-table-holder .hstb-table .row.trow:focus .td {
    color: #5993fa;
}

.hstb-pricing-table-holder .hstb-table .row.trow:hover .td .register-button, .hstb-pricing-table-holder .hstb-table .row.trow:focus .td .register-button {
    background-color: #5993fa;
}

.hstb-pricing-table-holder .hstb-table .row.trow:hover .td .register-button:hover, .hstb-pricing-table-holder .hstb-table .row.trow:hover .td .register-button:focus, .hstb-pricing-table-holder .hstb-table .row.trow:focus .td .register-button:hover, .hstb-pricing-table-holder .hstb-table .row.trow:focus .td .register-button:focus {
    background-color: #4083f9;
}

/*------------------------------------------------------------------
	15 - Domain Features Section Styles
-------------------------------------------------------------------*/
.domain-features {
    padding-top: 40px;
    padding-bottom: 80px;
    text-align: center;
}

.domain-features .row-title {
    font-size: 27px;
    font-weight: 700;
    color: #4d5666;
    margin-bottom: 40px;
}

.dfeature-box {
    text-align: center;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    max-width: 272px;
}

.dfeature-box .icon {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    height: 106px;
    background-color: #ffffff;
    border-radius: 16px;
    text-align: center;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 8px 12px rgba(31, 27, 90, 0.08);
    box-shadow: 0 8px 12px rgba(31, 27, 90, 0.08);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.dfeature-box .icon i {
    font-size: 50px;
    background: -webkit-linear-gradient(to bottom, #45b35e, #6ad56a);
    background: linear-gradient(to bottom, #45b35e, #6ad56a);
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.dfeature-box .title {
    font-size: 19px;
    font-weight: 700;
    color: #3d5e98;
    margin-bottom: 15px;
}

.dfeature-box .details {
    font-size: 14px;
    font-weight: 300;
    color: #090a19;
}

.dfeature-box:hover .icon {
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -ms-transform: scale(1.07);
    transform: scale(1.07);
}

/*------------------------------------------------------------------
	16 - Plain Content Section Styles
-------------------------------------------------------------------*/
.plain-content {
    padding-top: 60px;
    padding-bottom: 60px;
}

.plain-content h4 {
    font-size: 17px;
    font-weight: 700;
    color: #548df4;
    margin-bottom: 30px;
    line-height: 20px;
}

.plain-content p {
    font-size: 17px;
    font-weight: 300;
    color: #090a19;
    margin-bottom: 30px;
}

/*------------------------------------------------------------------
	17 - Other Sections Styles
-------------------------------------------------------------------*/
.text-photo-sc {
    text-align: left;
}

.text-photo-sc .photo-holder {
    background-size: cover;
    padding: 150px 0;
}

.text-photo-sc .photo-holder.photo1 {
    background-image: url("../images/photo1.jpg");
}

.text-photo-sc .photo-holder.photo2 {
    background-image: url("../images/photo2.jpg");
}

.text-photo-sc .photo-holder img {
    width: 100%;
}

.text-photo-sc .text-box {
    display: inline-block;
    width: 100%;
    max-width: 600px;
    padding: 60px 50px;
    text-align: left;
}

.text-photo-sc .text-box h4 {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 35px;
}

.text-photo-sc .text-box p {
    font-size: 15px;
    color: #bde0ff;
    margin-bottom: 25px;
}

.text-photo-sc .opposite {
    text-align: right;
}

.text-photo-sc.blue-bg .text-box p {
    color: #e1edff;
}

.text-photo-sc.darkblue-bg .text-box p {
    color: #bdc5d2;
}

.blue-bg {
    background-color: #2a74e3;
}

.darkblue-bg {
    background-color: #111a2b;
}

.search-row {
    position: relative;
    text-align: center;
    z-index: 10;
    margin-top: -28px;
    margin-bottom: -28px;
}

.search-question-holder {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 850px;
}

.search-question-holder ::-webkit-input-placeholder {
    color: #4189f5;
}

.search-question-holder :-moz-placeholder {
    color: #4189f5;
}

.search-question-holder ::-moz-placeholder {
    color: #4189f5;
}

.search-question-holder :-ms-input-placeholder {
    color: #4189f5;
}

.search-question-holder .search-input {
    width: 100%;
    font-size: 19px;
    font-weight: 700;
    color: #2a74e3;
    outline: none;
    border: 0;
    border-radius: 26px;
    padding: 14px 38px;
    -webkit-box-shadow: 0 6px 5px rgba(31, 27, 90, 0.06);
    box-shadow: 0 6px 5px rgba(31, 27, 90, 0.06);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.search-question-holder .search-input:hover, .search-question-holder .search-input:focus {
    -webkit-box-shadow: 0 6px 7px rgba(31, 27, 90, 0.08);
    box-shadow: 0 6px 7px rgba(31, 27, 90, 0.08);
}

.search-question-holder .btn-search {
    position: absolute;
    top: 11px;
    right: 13px;
    display: inline-block;
    width: 34px;
    height: 34px;
    border: 0;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.search-question-holder .btn-search:hover, .search-question-holder .btn-search:focus {
    opacity: 0.8;
}

.domain-search-only {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    background-color: #23395f;
}

.domain-search-only .domain-input-holder {
    display: inline-block;
    width: 100%;
    max-width: 820px;
}

.domain-search-only .domain-input-holder ::-webkit-input-placeholder {
    color: #4266a3;
}

.domain-search-only .domain-input-holder :-moz-placeholder {
    color: #4266a3;
}

.domain-search-only .domain-input-holder ::-moz-placeholder {
    color: #4266a3;
}

.domain-search-only .domain-input-holder :-ms-input-placeholder {
    color: #4266a3;
}

.domain-search-only .domain-input-holder .domain-input {
    display: inline-block;
    width: 72%;
    font-size: 19px;
    font-weight: 700;
    color: #3d5e98;
    border: 0;
    padding: 12px 34px;
    border-radius: 100px;
    outline: 0;
    text-align: left;
    -webkit-box-shadow: 0 14px 12px rgba(31, 27, 90, 0.1);
    box-shadow: 0 14px 12px rgba(31, 27, 90, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.domain-search-only .domain-input-holder .domain-input:hover, .domain-search-only .domain-input-holder .domain-input:focus {
    -webkit-box-shadow: 0 14px 16px rgba(31, 27, 90, 0.15);
    box-shadow: 0 14px 16px rgba(31, 27, 90, 0.15);
}

.domain-search-only .domain-input-holder button {
    display: inline-block;
    width: 23%;
    margin-left: 15px;
    font-size: 19px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    padding: 12px 22px;
    border-radius: 100px;
    background-color: #5993fa;
    outline: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.domain-search-only .domain-input-holder button:hover, .domain-search-only .domain-input-holder button:focus {
    background-color: #4083f9;
}



/*------------------------------------------------------------------
    19 - Responsive Styles
-------------------------------------------------------------------*/
@media (max-width: 1200px) {
    .main-slider .slide {
        padding-top: 120px;
    }

    .main-slider .b-text {
        font-size: 50px;
        line-height: 50px;
    }

    .light-header .main-slider .slide .b-text {
        font-size: 50px;
    }

    .custom-footer-menu ul.social li {
        margin-right: 20px;
    }

    .custom-footer-menu ul.social li:last-child {
        margin-right: 0;
    }

    .hstb-pricing-table-holder .hstb-table .row .th {
        font-size: 12px;
    }

    .features .feature-info .feature-title {
        font-size: 30px;
        margin-bottom: 13px;
    }

    .latest-news .post-box .post-title {
        max-width: 100%;
    }

    .footer-menu h4 {
        font-size: 17px;
        margin-bottom: 36px;
    }

    .footer-menu ul li {
        margin-bottom: 16px;
        font-size: 13px;
    }

    .footer-menu ul li a {
        font-size: 13px;
    }

    .footer-menu ul.social li a i {
        font-size: 20px;
    }

    .support-links > .row .hylink-holder:first-child {
        padding-left: 116px;
    }

    .support-links > .row .hylink-holder:last-child {
        padding-right: 116px;
    }
}

@media (max-width: 992px) {
    .same-height.rtl-row div[class^="col-"] {
        float: inherit;
    }

    nav.navbar .navbar-nav.other-navbar {
        display: none;
    }

    .light-header #top-content form .domain-input {
        font-size: 16px;
        padding: 10px 32px;
        margin-bottom: 20px;
    }

    .light-header #top-content form .hbtn.search {
        font-size: 16px;
        padding: 10px 32px;
        margin-left: 0;
    }

    .main-slider .slide {
        padding-top: 80px;
    }

    .main-slider .img-holder {
        margin-bottom: 30px;
    }

    .main-slider .img-holder img {
        margin-top: -40px;
    }

    .main-slider .b-text {
        font-size: 38px;
        line-height: 45px;
    }

    .light-header .main-slider .slide .b-text {
        margin-top: 0;
        font-size: 38px;
    }

    .main-domain-search-holder form {
        max-width: 620px;
    }

    .features .feature-info {
        padding: 50px 0;
    }

    .features .feature-info .feature-title {
        font-size: 27px;
        margin-bottom: 8px;
    }

    .features .img-holder {
        padding: 68px 0;
    }

    .features .def-aligned {
        text-align: left;
    }

    .domain-search-holder {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .domain-search-holder .title {
        margin-bottom: 15px;
        font-size: 25px;
    }

    .domain-search-holder .img-holder {
        display: none;
    }

    .domain-search-holder .domain-input-holder {
        margin-top: 20px;
    }

    .domain-search-holder .domain-input-holder .domain-input {
        width: 62%;
    }

    .domain-search-holder .domain-input-holder button {
        width: 33%;
    }

    .domain-search-only .domain-input-holder .domain-input {
        width: 62%;
    }

    .domain-search-only .domain-input-holder button {
        width: 33%;
    }

    .pricing-box .pricing-button a {
        font-size: 18px;
    }

    .pricing .row-title {
        font-size: 25px;
        margin-bottom: 15px;
    }

    .domain-pricing .row-title {
        font-size: 25px;
    }

    .domain-features .row-title {
        font-size: 25px;
    }

    .latest-news .row-title {
        font-size: 25px;
    }

    .support-links > .row .hylink-holder {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .support-links > .row .hylink-holder:first-child {
        padding-right: 20px;
        padding-left: 120px;
    }

    .support-links > .row .hylink-holder:last-child {
        padding-left: 35px;
        padding-right: 100px;
    }

    .footer-menu h4 {
        margin-bottom: 30px;
    }

    .sub-footer .sub-footer-menu {
        text-align: center;
        margin-bottom: 10px;
    }

    .sub-footer .copyright {
        text-align: center;
    }

    .text-photo-sc .text-box {
        max-width: 100%;
    }

    .text-photo-sc .opposite {
        text-align: left;
    }

    .hstb-pricing-table-holder {
        overflow: scroll;
        border-radius: 10px;
        border: 1px solid #d3d3dd;
    }

    .hstb-pricing-table-holder .hstb-table .row {
        border-left: 0px solid #d3d3dd;
        border-right: 0px solid #d3d3dd;
    }

    .hstb-pricing-table-holder .hstb-table .row.thead {
        border-top: 0px solid #d3d3dd;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .hstb-pricing-table-holder .hstb-table .row:last-child {
        border-bottom: 0px solid #d3d3dd;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .hstb-pricing-table-holder .hstb-table .row .th {
        padding: 17px 10px;
    }

    .hstb-pricing-table-holder .hstb-table .row .td {
        padding: 17px 10px;
    }

    .hstb-pricing-table-holder .hstb-table .row.trow:before {
        height: 54px;
        margin-top: -27px;
        border: 0px solid rgba(211, 211, 221, 0);
    }

    .hstb-pricing-table-holder .hstb-table .row.trow:hover:before, .hstb-pricing-table-holder .hstb-table .row.trow:focus:before {
        border: 0px solid #d3d3dd;
        border-radius: 0;
        -webkit-transform: scaleX(1) scaleY(1);
        -moz-transform: scaleX(1) scaleY(1);
        -ms-transform: scaleX(1) scaleY(1);
        transform: scaleX(1) scaleY(1);
    }

    .text-photo-sc .rtl-row div[class^="col-"] {
        float: inherit;
    }

    .text-photo-sc .photo-holder {
        max-height: 350px;
    }

    .support-details {
        padding-top: 55px;
        padding-bottom: 35px;
    }

    .support-box {
        position: relative;
        text-align: left;
        padding: 0 30px 0 110px;
        margin: 25px 0;
    }

    .support-box .icon {
        margin-left: auto;
        margin-right: auto;
        width: 80px;
        height: 80px;
        margin-bottom: 13px;
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 0;
    }

    .support-box .icon i {
        font-size: 38px;
    }

    .contact-us .address-details {
        padding-left: 0;
    }
}

@media (max-width: 768px) {
    #header-holder .custom-bg {
        background-size: 70%;
    }

    .rtl-row div[class^="col-"] {
        float: inherit;
    }

    nav.navbar .navbar-collapse {
        text-align: center;
    }

    nav.navbar .navbar-nav {
        width: 100%;
        margin: 0;
    }

    nav.navbar .navbar-nav > li {
        padding: 10px 16px;
    }

    nav.navbar .navbar-nav > li > a:after {
        display: none;
    }

    nav.navbar .navbar-nav > li > a.dropdown-toggle {
        margin-bottom: 5px;
    }

    nav.navbar .navbar-nav > li > a.btn-client-area {
        display: inline-block;
    }

    nav.navbar .navbar-nav > li > a.btn-chat {
        display: none;
    }

    nav.navbar .navbar-nav > li.active > a {
        color: #ffc600;
    }

    nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu {
        width: 100%;
        max-width: 100%;
    }

    nav.navbar .navbar-nav > li .chat-info {
        display: none;
    }

    nav.navbar .navbar-nav.other-navbar {
        display: inherit;
    }

    .main-domain-search-holder form {
        max-width: 520px;
    }

    .main-domain-search-holder .input-items .input-items-btn {
        position: relative;
        display: block;
        margin-bottom: 20px;
        margin-top: 20px;
        top: 0;
        right: 0;
    }

    .main-domain-search-holder .form-control.domain-input {
        padding: 10px 32px;
    }

    .features .img-holder {
        padding: 40px 0;
    }

    .features .img-holder img {
        max-width: 260px;
    }

    .features .feature-info {
        max-width: 100%;
    }

    .latest-news .row-title {
        text-align: center;
    }

    .latest-news .main-btn-holder {
        text-align: center;
    }

    .domain-search-holder .domain-input {
        margin-bottom: 15px;
    }

    .support-links > .row .hylink-holder:first-child, .support-links > .row .hylink-holder:last-child {
        padding-left: 30px;
        padding-right: 30px;
        text-align: left;
    }

    .hylink-box {
        max-width: 100%;
    }

    .domain-search-holder .domain-input-holder .domain-input {
        width: 100%;
        margin-bottom: 10px;
    }

    .domain-search-holder .domain-input-holder button {
        width: 100%;
        margin-left: 0;
    }

    .domain-search-only .domain-input-holder .domain-input {
        width: 100%;
        margin-bottom: 10px;
    }

    .domain-search-only .domain-input-holder button {
        width: 100%;
        margin-left: 0;
    }

    .support-details {
        padding-top: 55px;
        padding-bottom: 35px;
    }

    .support-box {
        position: relative;
        text-align: left;
        padding: 0 30px 0 110px;
        margin: 25px 0;
    }

    .support-box .icon {
        margin-left: auto;
        margin-right: auto;
        width: 80px;
        height: 80px;
        margin-bottom: 13px;
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 0;
    }

    .support-box .icon i {
        font-size: 38px;
    }

    .form-row > .col, .form-row > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .support-details > .container > .row > [class^="col"] {
        padding-left: 15px;
        padding-right: 15px;
    }

    .service-box {
        margin: 15px 0;
    }
}

@media (max-width: 480px) {
    nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items .link {
        margin-bottom: 5px;
    }

    nav.navbar .navbar-toggle {
        margin-right: 0;
    }

    .navbar-brand {
        padding: 24px 0;
    }

    .main-slider .b-text {
        font-size: 30px;
        line-height: 35px;
    }

    .light-header .main-slider .slide .b-text {
        font-size: 30px;
    }

    .features {
        padding-bottom: 40px;
    }

    .features .img-holder {
        padding: 40px 0;
    }

    .features .feature-info {
        padding: 10px 0 60px;
    }

    .features .feature-info .feature-title {
        font-size: 25px;
    }

    .features > .container > .row:last-child .feature-info {
        padding-bottom: 20px;
    }

    .text-photo-sc .text-box {
        padding: 40px 20px;
    }

    .pricing {
        padding-bottom: 40px;
    }

    .pricing .row-title {
        font-size: 25px;
        margin-bottom: 5px;
        text-align: left;
    }

    .latest-news {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .latest-news .row-title {
        font-size: 25px;
        text-align: left;
        margin-bottom: 5px;
    }

    .latest-news .main-btn-holder {
        display: none;
    }

    .domain-search-holder {
        padding-top: 35px;
        padding-bottom: 40px;
    }

    .domain-search-holder .title {
        font-size: 25px;
        margin-bottom: 15px;
    }

    .domain-search-holder .domain-input {
        text-align: center;
    }

    .domain-search-holder button {
        font-size: 19px;
        font-weight: 700;
    }

    .domain-search-holder .domain-input-holder {
        margin-top: 25px;
    }

    .support-links > .row .hylink-holder {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .hylink-box {
        padding: 0 15px 15px 100px;
    }

    .hylink-box .icon {
        width: 70px;
        height: 70px;
    }

    .hylink-box .icon img {
        width: 35px;
    }

    .pricing-box.inner {
        margin: 20px 0;
    }

    .sub-pricing {
        padding-bottom: 20px;
    }

    .domain-search-only .domain-input-holder .domain-input {
        text-align: center;
    }

    .domain-search-only .domain-input-holder button {
        font-weight: 700;
    }

    .domain-pricing .info-img {
        display: none;
    }

    .domain-pricing .row-title {
        font-size: 25px;
    }

    .domain-features {
        padding-bottom: 40px;
    }

    .domain-features .row-title {
        font-size: 25px;
    }

    .question-card .card-header button {
        font-size: 16px;
    }

    .question-card .card-header button:after {
        top: 8px;
        font-size: 22px;
    }

    .question-card .card-body {
        font-size: 16px;
    }

    .question-card .card-body p {
        font-size: 16px;
    }
}
