body p[style="text-align:center;"] {
    display: inline-block;
    position: relative;
    background-color: #bdc3d4;
    padding: 4px 14px;
    border-radius: 16px;
    color: #fff;
    font-size: 13px;
    margin: 10px 10px;
    z-index: 20;
    left: 50%;
    margin-left: -130px;
}

body p[style="text-align:center;"] a {
    color: #3D4461;
}

body.fullpage p[style="text-align:center;"] {
    background-color: rgba(189, 195, 212, 0.18);
    opacity: 0.7;
}

body.fullpage p[style="text-align:center;"] a {
    color: #ffffff;
}

a {
    color: #2b74e3;
}

a:hover, a:focus {
    color: #1d67d8;
}

#header-holder {
    z-index: 12 !important;
}

#header-holder.no-bg-img:before {
    display: none;
}

.sub-pricing {
    z-index: 1;
}

section#header {
    background-color: transparent;
}

section#header .navbar-brand {
    padding: 10px 15px;
}

section#header ul.top-nav > li > a {
    color: #ffffff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

section#header ul.top-nav > li > a:hover, section#header ul.top-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
}

section#header ul.top-nav > li > a.btn {
    background-color: #2a74e3;
    border-radius: 10px;
}

section#header ul.top-nav > li > a.btn:hover, section#header ul.top-nav > li > a.btn:focus {
    color: #fff;
    background-color: #1c67d7;
}

section#header ul.top-nav > li > a.btn-logged-in-admin {
    background-color: transparent;
}

section#header ul.top-nav > li > a.btn-logged-in-admin:hover, section#header ul.top-nav > li > a.btn-logged-in-admin:focus {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.7);
}

section#header ul.top-nav > li.primary-action {
    border-left: 0;
}

.light-header section#header ul.top-nav > li > a {
    color: #333c4f;
}

.light-header section#header ul.top-nav > li > a:hover, .light-header section#header ul.top-nav > li > a:focus {
    color: rgba(51, 60, 79, 0.7);
}

.light-header section#header ul.top-nav > li > a.btn {
    color: #fff;
}

.light-header section#header ul.top-nav > li > a.btn:hover, .light-header section#header ul.top-nav > li > a.btn:focus {
    color: #fff;
}

.light-header section#header ul.top-nav > li > a.btn.btn-logged-in-admin {
    color: #333c4f;
}

.light-header section#header ul.top-nav > li > a.btn.btn-logged-in-admin:hover, .light-header section#header ul.top-nav > li > a.btn.btn-logged-in-admin:focus {
    color: rgba(51, 60, 79, 0.7);
}

section#main-menu {
    background-color: transparent;
}

section#main-menu .navbar-main {
    background-color: transparent;
}

section#main-menu .navbar-main li.account {
    background-color: transparent;
}

section#main-menu .nav .dropdown .dropdown-menu > li a {
    background-color: transparent;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

section#main-menu .nav .dropdown .dropdown-menu > li a:hover, section#main-menu .nav .dropdown .dropdown-menu > li a:focus {
    opacity: 0.7;
}

.domainchecker-homepage-captcha p {
    font-size: 16px;
    font-weight: 300;
    color: #8695af;
}

.domainchecker-homepage-captcha .captchaimage img {
    display: inline-block;
    margin-right: 10px;
}

.latest-news .post-box {
    min-height: 260px;
}

.latest-news .post-box .post-title {
    max-width: 100% !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #101b2c;
}

.h2, h2 {
    font-size: 27px;
}

div.header-lined h1 {
    color: #101b2c;
    font-size: 30px;
}

div.header-lined small {
    font-size: 58%;
    color: #9599a5;
}

div.header-lined .breadcrumb > .active {
    color: #101b2c;
}

.announcement-single .title {
    font-size: 17px;
    font-weight: 700;
    color: #548df4;
    display: inline-block;
    margin-bottom: 8px;
}

.announcement-single p {
    font-size: 17px;
    font-weight: 300;
    color: #090a19;
}

p {
    font-size: 17px;
    font-weight: 300;
    color: #090a19;
}

.label {
    border-radius: 8px;
}

.panel {
    border-radius: 0px;
}

.panel > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.panel-sidebar {
    border-color: #e6e5f1;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.panel-sidebar > .panel-heading {
    background-color: #fff;
    color: #111a2b;
    border: 1px solid #2b74e3;
    border-radius: 10px;
}

.panel-sidebar > .panel-footer {
    border-radius: 10px;
}

.panel-sidebar .panel-title {
    color: #111a2b;
    font-weight: 400;
    font-family: inherit;
}

.panel-sidebar .panel-title [class*="fa"] {
    color: #2b74e3;
}

.panel-sidebar .panel-title i.panel-minimise {
    color: #ffc601;
}

.panel-sidebar .list-group {
    padding-top: 10px;
}

.panel-sidebar a.list-group-item {
    border-radius: 10px !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.panel-sidebar a.list-group-item i {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.panel-sidebar a.list-group-item.active {
    background-color: #fff;
    border: 1px solid #dce2ea !important;
    color: #101b2c;
}

.panel-sidebar a.list-group-item.active i {
    color: #101b2c !important;
}

.panel-sidebar a.list-group-item.active:hover, .panel-sidebar a.list-group-item.active:focus {
    background-color: #fff;
    border: 1px solid #dce2ea !important;
    color: #101b2c;
}

.panel-sidebar a.list-group-item.active:hover i, .panel-sidebar a.list-group-item.active:focus i {
    color: #101b2c !important;
}

.panel-heading {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.sidebar .list-group-item .badge {
    padding: 8px;
}

#order-hustbee_cart h3 {
    font-size: 27px !important;
    font-weight: 700 !important;
    color: #4d5666 !important;
}

#order-hustbee_cart h5 {
    font-size: 16px !important;
    color: #a6aebb !important;
}

#order-hustbee_cart .pricing-box.inner {
    padding: 24px 15px;
    margin: 35px 0;
}

#order-hustbee_cart .pricing-box.inner .pricing-title, #order-hustbee_cart .pricing-box.inner .pricing-price {
    line-height: initial;
}

#order-hustbee_cart .pricing-box.inner .pricing-title {
    margin-bottom: 15px;
}

#order-hustbee_cart .pricing-box.inner .pricing-price {
    margin-bottom: 15px;
}

#order-hustbee_cart .pricing-box.inner .pricing-details > ul > li {
    display: block !important;
    text-align: center !important;
    padding: 6px 5px !important;
    margin-bottom: 3px !important;
    background: none !important;
}

#order-hustbee_cart .pricing-box.inner .pricing-details > ul > li + br {
    display: none;
}

#order-hustbee_cart .pricing-box.inner .pricing-details > ul > li:before {
    top: 6px;
}

#order-hustbee_cart .pricing-box.inner:hover, #order-hustbee_cart .pricing-box.inner.featured {
    padding: 34px 15px;
    margin: 25px 0;
}

#order-hustbee_cart .pricing-holder .row {
    margin-left: -15px;
    margin-right: -15px;
}

#order-hustbee_cart .pricing-holder .row > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
}

.btn-default {
    color: #fff;
    background-color: #2b74e3;
    border: 0;
}

.btn-default:hover, .btn-default:focus {
    color: #fff;
    background-color: #1d67d8;
}

.btn-primary {
    background-color: #ffc600;
    color: #0c090d;
    border: 0;
}

.btn-primary:hover, .btn-primary:focus {
    color: #0c090d;
    text-decoration: none;
    background-color: #e6b200;
}

.btn-link {
    color: #2b74e3;
}

.btn-link:hover, .btn-link:focus {
    color: #1d67d8;
}

.btn {
    border-radius: 9px;
    outline: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn.bg-color-gold {
    background-color: #f0ad4e;
}

.btn.bg-color-gold:hover, .btn.bg-color-gold:focus {
    background-color: #ec971f;
}

.btn.bg-color-green {
    background-color: #5cb85c;
}

.btn.bg-color-green:hover, .btn.bg-color-green:focus {
    background-color: #449d44;
}

.btn.bg-color-red {
    background-color: #d9534f;
}

.btn.bg-color-red:hover, .btn.bg-color-red:focus {
    background-color: #c9302c;
}

.btn.bg-color-blue {
    background-color: #5bc0de;
}

.btn.bg-color-blue:hover, .btn.bg-color-blue:focus {
    background-color: #31b0d5;
}

.btn.bg-color-orange {
    background-color: #f39c12;
}

.btn.bg-color-orange:hover, .btn.bg-color-orange:focus {
    background-color: #c87f0a;
}

.btn.bg-color-pink {
    background-color: #e671b8;
}

.btn.bg-color-pink:hover, .btn.bg-color-pink:focus {
    background-color: #de46a2;
}

.btn.bg-color-purple {
    background-color: #7b4f9d;
}

.btn.bg-color-purple:hover, .btn.bg-color-purple:focus {
    background-color: #603e7b;
}

.btn.bg-color-lime {
    background-color: #8cbf26;
}

.btn.bg-color-lime:hover, .btn.bg-color-lime:focus {
    background-color: #6d941e;
}

.btn.bg-color-magenta {
    background-color: #ff0097;
}

.btn.bg-color-magenta:hover, .btn.bg-color-magenta:focus {
    background-color: #cc0079;
}

.btn.bg-color-teal {
    background-color: #00aba9;
}

.btn.bg-color-teal:hover, .btn.bg-color-teal:focus {
    background-color: #007877;
}

.btn.bg-color-turquoise {
    background-color: #1abc9c;
}

.btn.bg-color-turquoise:hover, .btn.bg-color-turquoise:focus {
    background-color: #148f77;
}

.btn.bg-color-emerald {
    background-color: #2ecc71;
}

.btn.bg-color-emerald:hover, .btn.bg-color-emerald:focus {
    background-color: #25a25a;
}

.btn.bg-color-amethyst {
    background-color: #9b59b6;
}

.btn.bg-color-amethyst:hover, .btn.bg-color-amethyst:focus {
    background-color: #804399;
}

.btn.bg-color-wet-asphalt {
    background-color: #34495e;
}

.btn.bg-color-wet-asphalt:hover, .btn.bg-color-wet-asphalt:focus {
    background-color: #22303d;
}

.btn.bg-color-midnight-blue {
    background-color: #2c3e50;
}

.btn.bg-color-midnight-blue:hover, .btn.bg-color-midnight-blue:focus {
    background-color: #1a252f;
}

.btn.bg-color-sun-flower {
    background-color: #f1c40f;
}

.btn.bg-color-sun-flower:hover, .btn.bg-color-sun-flower:focus {
    background-color: #c29d0b;
}

.btn.bg-color-pomegranate {
    background-color: #c0392b;
}

.btn.bg-color-pomegranate:hover, .btn.bg-color-pomegranate:focus {
    background-color: #962d22;
}

.btn.bg-color-silver {
    background-color: #bdc3c7;
}

.btn.bg-color-silver:hover, .btn.bg-color-silver:focus {
    background-color: #a1aab0;
}

.btn.bg-color-asbestos {
    background-color: #7f8c8d;
}

.btn.bg-color-asbestos:hover, .btn.bg-color-asbestos:focus {
    background-color: #667273;
}

.kb-search .form-control {
    border-radius: 9px;
}

.kb-search > .input-group-btn > .btn {
    border-radius: 0 9px 9px 0;
}

.alert-info {
    color: #596475;
    background-color: #eaf2fe;
    border-color: #dde5f3;
}

.kbarticles a {
    font-size: 17px;
    font-weight: 700;
    color: #548df4;
    display: inline-block;
    margin-bottom: 8px;
}

.kbarticles a .glyphicon {
    color: #ccc;
}

a.admin-inline-edit {
    color: #888;
}

.kb-rate-article {
    color: #596475;
    background-color: #eaf2fe;
}

.login-register-form .form-holder .form-title .header-lined h1 {
    margin-bottom: 0;
    padding: 0;
    border-bottom: 0;
    font-size: 18px;
    color: #5288e8;
    line-height: initial;
}

.login-register-form .form-holder .forget-link {
    margin-bottom: 0 !important;
}

.login-register-form .form-holder #registration .sub-heading {
    border-top: 1px solid #4e5561;
    text-align: left;
}

.login-register-form .form-holder #registration .sub-heading span {
    color: #656c79;
    background-color: #273041;
    border-radius: 10px;
}

.login-register-form .form-holder #registration select.form-control {
    padding: 9px 24px;
    padding-left: 36px;
}

.login-register-form .form-holder #registration .form-control, .login-register-form .form-holder #registration .field {
    margin-bottom: 15px;
    border: 0;
}

.login-register-form .form-holder #registration #default-captcha-domainchecker p {
    color: #636a77;
}

.form-linked .providerPreLinking {
    margin-bottom: 15px;
}

.client-home-panels .panel > .panel-heading .panel-title .btn:hover, .client-home-panels .panel > .panel-heading .panel-title .btn:focus {
    color: #fff;
}

.tiles .tile {
    padding: 0;
    border: 0;
    background-color: transparent;
    padding-right: 10px;
}

.tiles .tile:last-child {
    padding-right: 0;
}

.tiles .tile .icon {
    top: 16px;
    right: inherit;
    left: 26px;
}

.tiles .tile .icon i {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #ffc601;
}

.tiles .tile .stat {
    font-size: 34px;
    color: #fff;
    text-align: right;
}

.tiles .tile .title {
    color: #76849e;
    text-align: right;
}

.tiles .tile .highlight {
    background-color: #76849e;
}

.tiles .tile a {
    padding: 12px 20px;
    display: block;
    background-color: #101b2c;
    border-radius: 10px;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.tiles .tile a:hover, .tiles .tile a:focus {
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}

.tiles .tile a:hover .icon i, .tiles .tile a:focus .icon i {
    color: #2b74e3;
}

.tiles .tile:hover, .tiles .tile:focus {
    background-color: transparent;
}

.home-kb-search .form-control {
    background-color: #f5f5f5;
}

.client-home-panels .panel {
    border-top: 3px solid #ffc601;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.client-home-panels .panel .panel-footer {
    display: none;
}

.client-home-panels .panel > .panel-heading .panel-title {
    color: #0f1c2d;
    padding: 6px 0;
}

.client-home-panels .panel > .panel-heading .panel-title .btn {
    color: #fff;
    background-color: #2b74e3;
    padding: 3px 12px;
}

.client-home-panels .panel > .panel-heading .panel-title .btn:hover, .client-home-panels .panel > .panel-heading .panel-title .btn:focus {
    background-color: #1a5cc1;
}

.client-home-panels .panel > .panel-heading .panel-title .pull-right {
    vertical-align: middle;
    margin-top: -3px;
}

.domain-checker-container {
    background: none;
}

.domain-checker-container .domain-checker-bg {
    background: none;
}

.domain-checker-container .input-group-box {
    padding: 0;
}

#order-standard_cart .input-group-lg > .form-control {
    border-radius: 24px 0 0 24px !important;
}

#order-standard_cart .input-group-lg button {
    border-radius: 0 24px 24px 0;
}

#order-standard_cart .view-cart-items-header {
    background-color: #2b74e3;
}

#order-standard_cart .view-cart-items {
    border-bottom: 2px solid #2c74e3;
}

#order-standard_cart .order-summary {
    background-color: #94a5c3;
    border-bottom: 3px solid #93a4c3;
    border-radius: 10px;
}

#order-standard_cart .summary-container {
    background-color: #f9f9fb;
}

.domain-promo-box {
    background-color: #f8f8fb;
    border: 1px solid #e4e4ea;
    color: #101b2c;
    border-radius: 10px;
}

.domain-promo-box i {
    font-size: 2em;
}

.domain-pricing .tld-pricing-header div :nth-child(odd) {
    border-color: #2b74e3;
}

.domain-pricing .tld-row.highlighted {
    background: #eff2f5;
}

.domain-pricing .tld-pricing-header .col-sm-2, .domain-pricing .tld-pricing-header .col-sm-4, .domain-pricing .tld-pricing-header .col-xs-2, .domain-pricing .tld-pricing-header .col-xs-4 {
    border-bottom: 6px solid #c1c6ce;
}
#main-body nav.navbar .navbar-nav > li > a {
    color: #101a2b;
    border-bottom: 0;
}
select {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-chevron-down fa-w-14 fa-3x'%3E%3Cpath fill='currentColor' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right .9em top 50% !important;
    background-size: .85em auto !important;
}
/*------------------------------------------------------------------
	- Responsive Styles
-------------------------------------------------------------------*/
@media (max-width: 992px) {
    nav.navbar .navbar-collapse {
        border-color: transparent;
    }
}

@media (max-width: 768px) {
    #main-menu .navbar-nav {
        margin: 0;
    }

    .navbar-main .navbar-nav .open .dropdown-menu > li > a {
        color: #101b2c;
    }

    .navbar-main .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-main .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #020407;
    }

    ul.top-nav {
        margin-top: 7px;
    }

    .navbar-brand img {
        width: 110px;
    }
}

@media (max-width: 480px) {
    .social-signin-btns .btn-social {
        width: 100%;
    }
}
