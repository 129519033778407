/* ----------------------------------
    2 - Header Styles
-----------------------------------*/
#header-holder {
    position: relative;
    background-color: #111a2b;
    z-index: 10;
}

#header-holder .cloud-bg {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/cloud-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 110%;
    background-position: 50% 100%;
    opacity: 0.17;
    z-index: -1;
}

#header-holder .custom-bg {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/custom-bg.svg");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 100% 20%;
    opacity: 0.8;
    z-index: -1;
}

#header-holder.innerpage:before {
    background-image: none;
}

#header-holder.innerpage.clouds-bg:before {
    background-image: url("../../images/cloud-bg-df.png");
}

#header-holder.innerpage.lighter {
    background-color: #152c53;
}

#header-holder.innerpage.lighter nav.navbar .navbar-nav.other-navbar > li .btn-client-area {
    background-color: rgba(51, 60, 78, 0.25);
}

#header-holder.innerpage.lighter nav.navbar .navbar-nav.other-navbar > li .btn-client-area:hover, #header-holder.innerpage.lighter nav.navbar .navbar-nav.other-navbar > li .btn-client-area:focus {
    background-color: rgba(51, 60, 78, 0.45);
}

#header-holder.innerpage.light-bg {
    background-color: #5993f9;
}

#header-holder.innerpage.light-bg nav.navbar .navbar-nav.other-navbar > li .btn-client-area {
    color: #ffffff;
    background-color: rgba(51, 60, 78, 0.25);
}

#header-holder.innerpage.light-bg nav.navbar .navbar-nav.other-navbar > li .btn-client-area:hover, #header-holder.innerpage.light-bg nav.navbar .navbar-nav.other-navbar > li .btn-client-area:focus {
    background-color: rgba(51, 60, 78, 0.45);
}

#header-holder.innerpage.grey-bg {
    background-color: #838ea2;
}

#header-holder.innerpage.grey-bg nav.navbar .navbar-nav.other-navbar > li .btn-client-area {
    color: #ffffff;
    background-color: rgba(51, 60, 78, 0.25);
}

#header-holder.innerpage.grey-bg nav.navbar .navbar-nav.other-navbar > li .btn-client-area:hover, #header-holder.innerpage.grey-bg nav.navbar .navbar-nav.other-navbar > li .btn-client-area:focus {
    background-color: rgba(51, 60, 78, 0.45);
}
#header-holder.innerpage.custom-flow{
    background-color: transparent;
    z-index: 1;
}
#header-holder.innerpage.custom-flow:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 412px;
    background-image: url("../../images/cloud-bg-df.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    background-position: bottom center;
    opacity: 0.17;
    z-index: 0;
}
#header-holder.innerpage.custom-flow:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 412px;
    background-color: #111a2b;
    z-index: -1;
}
#header-holder.innerpage.custom-flow.navbar,
#header-holder.innerpage.custom-flow#header,
#header-holder.innerpage.custom-flow#main-menu{
    position:relative;
    z-index: 99;
}
#header-holder.light-header {
    background-color: #EDF4FF;
}

#header-holder.light-header .cloud-bg {
    background-image: url("../../images/cloud-bg2.svg");
    opacity: 1;
}

.navbar-brand {
    padding: 24px 15px;
}

.navbar-brand img {
    width: 122px;
}

nav.navbar .navbar-toggler {
    outline: 0;
    border: 0;
    color: #ffffff;
}

nav.navbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

nav.navbar .navbar-toggle {
    margin-top: 23px;
}

nav.navbar .navbar-toggle .icon-bar {
    background-color: #ffffff;
}

nav.navbar .navbar-nav > li {
    padding: 14px 16px;
}

nav.navbar .navbar-nav > li > a {
    color: #ffffff;
    font-size: 16px;
    position: relative;
    padding: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav.navbar .navbar-nav > li > a:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -4px;
    width: 0;
    height: 2px;
    background-color: #ffc600;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav.navbar .navbar-nav > li > a:hover, nav.navbar .navbar-nav > li > a:focus {
    background-color: transparent;
}

nav.navbar .navbar-nav > li > a:hover:after, nav.navbar .navbar-nav > li > a:focus:after {
    width: 100%;
}

nav.navbar .navbar-nav > li.active > a:after {
    width: 100%;
}

nav.navbar .navbar-nav > li.dropdown .dropdown-toggle {
    opacity: 0.8;
}

nav.navbar .navbar-nav > li.dropdown .dropdown-toggle:hover, nav.navbar .navbar-nav > li.dropdown .dropdown-toggle:focus {
    opacity: 0.5;
}

nav.navbar .navbar-nav > li.dropdown.show .dropdown-toggle {
    opacity: 0.5;
}

nav.navbar .navbar-nav > li.dropdown > a:after {
    display: none;
}

nav.navbar .navbar-nav > li.dropdown .dropdown-menu {
    border-radius: 10px;
    background-color: #e6ebf3;
}

nav.navbar .navbar-nav > li.dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
    font-weight: 300;
    color: #111a2b;
}

nav.navbar .navbar-nav > li.dropdown .dropdown-menu .dropdown-item:hover, nav.navbar .navbar-nav > li.dropdown .dropdown-menu .dropdown-item:focus {
    color: black;
    background-color: transparent;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu {
    width: 400px;
    padding: 15px;
    border-radius: 10px;
    background-color: #e6ebf3;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon {
    border-bottom: 1px solid #98a9c3;
    padding-bottom: 10px;
    margin-bottom: 8px;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon > .row {
    margin-left: 0;
    margin-right: 0;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon > .row > [class^="col-"] {
    padding-left: 0;
    padding-right: 0;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon {
    display: block;
    width: 100%;
    text-decoration: none;
    margin-bottom: 7px;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon .icon {
    width: 36px;
    height: 36px;
    background-color: transparent;
    display: inline-block;
    border-radius: 36px;
    text-align: center;
    vertical-align: middle;
    margin-right: 4px;
    padding-top: 6px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon .icon img {
    width: 22px;
    -webkit-filter: grayscale(100%) brightness(170%);
    -moz-filter: grayscale(100%) brightness(170%);
    -ms-filter: grayscale(100%) brightness(170%);
    filter: grayscale(100%) brightness(170%);
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon .text {
    font-size: 14px;
    font-weight: 300;
    color: #111a2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:hover .icon, nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:focus .icon, nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon.active .icon {
    background-color: #5993fa;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:hover .text, nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:focus .text, nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon.active .text {
    color: #020204;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items > .row {
    margin-left: 0;
    margin-right: 0;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items > .row > [class^="col-"] {
    padding-left: 0;
    padding-right: 0;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items .link {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    padding-left: 40px;
    color: #111a2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items .link:before {
    position: absolute;
    font-family: "hustbee" !important;
    content: "\62";
    top: 3px;
    left: 12px;
    font-weight: 400;
    font-size: 11px;
    color: #98a9c3;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

nav.navbar .navbar-nav.other-navbar > li > a:after {
    display: none;
}

nav.navbar .navbar-nav.other-navbar > li .btn-client-area {
    font-size: 14px;
    color: #a9b6c4;
    border-radius: 20px;
    padding: 2px 18px;
    margin-bottom: 5px;
    background-color: #333c4e;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav.navbar .navbar-nav.other-navbar > li .btn-client-area img {
    width: 11px;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 8px;
}

nav.navbar .navbar-nav.other-navbar > li .btn-client-area:hover, nav.navbar .navbar-nav.other-navbar > li .btn-client-area:focus {
    background-color: #29303f;
}

nav.navbar .navbar-nav.other-navbar > li .chat-info {
    font-size: 14px;
    color: #ffffff;
}

nav.navbar .navbar-nav.other-navbar > li .chat-info i {
    color: #2a74e3;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -3px;
}

nav.navbar .navbar-nav.other-navbar > li .btn-chat {
    background-color: #2a74e3;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    padding-top: 12px;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav.navbar .navbar-nav.other-navbar > li .btn-chat i {
    color: #ffffff;
    font-size: 30px;
}

nav.navbar .navbar-nav.other-navbar > li .btn-chat:hover, nav.navbar .navbar-nav.other-navbar > li .btn-chat:focus {
    background-color: #1c67d7;
}

nav.navbar .navbar-nav-centered {
    display: inline-block;
    float: none;
    margin-top: 14px;
}

nav.navbar .navbar-collapse-centered {
    text-align: center;
}

nav.navbar .nav .open > a {
    background-color: transparent;
    border-color: transparent;
}

nav.navbar .nav .open > a:hover, nav.navbar .nav .open > a:focus {
    background-color: transparent;
    border-color: transparent;
}

.light-header nav.navbar .navbar-toggler {
    color: #2A74E3;
}

.light-header nav.navbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.light-header nav.navbar .navbar-toggle .icon-bar {
    background-color: #2A74E3;
}

.light-header nav.navbar .navbar-nav > li > a {
    color: #333C4E;
}

.light-header nav.navbar .navbar-nav > li > a:after {
    background-color: #006BFF;
}

.light-header nav.navbar .navbar-nav > li.dropdown .dropdown-toggle {
    opacity: 0.5;
}

.light-header nav.navbar .navbar-nav > li.dropdown .dropdown-toggle:hover, .light-header nav.navbar .navbar-nav > li.dropdown .dropdown-toggle:focus {
    color: #949aaa;
}

.light-header nav.navbar .navbar-nav > li.dropdown .dropdown-menu {
    background-color: #e6ebf3;
}

.light-header nav.navbar .navbar-nav > li.dropdown .dropdown-menu .dropdown-item {
    color: #111a2b;
}

.light-header nav.navbar .navbar-nav > li.dropdown .dropdown-menu .dropdown-item:hover, .light-header nav.navbar .navbar-nav > li.dropdown .dropdown-menu .dropdown-item:focus {
    color: black;
}

.light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu {
    background-color: #e6ebf3;
}

.light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon {
    border-bottom: 1px solid #98a9c3;
}

.light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon .text {
    color: #111a2b;
}

.light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:hover .icon, .light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:focus .icon, .light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon.active .icon {
    background-color: #5993fa;
}

.light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:hover .text, .light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon:focus .text, .light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items-with-icon .link-with-icon.active .text {
    color: #020204;
}

.light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items .link {
    color: #111a2b;
}

.light-header nav.navbar .navbar-nav > li.dropdown .custom-dropdown-menu .dropdown-items-holder .items .link:before {
    color: #98a9c3;
}

.light-header nav.navbar .navbar-nav.other-navbar > li .btn-client-area {
    color: #a9b6c4;
    background-color: #333c4e;
}

.light-header nav.navbar .navbar-nav.other-navbar > li .btn-client-area:hover, .light-header nav.navbar .navbar-nav.other-navbar > li .btn-client-area:focus {
    background-color: #29303f;
}

.light-header nav.navbar .navbar-nav.other-navbar > li .chat-info {
    color: #2A74E3;
}

.light-header nav.navbar .navbar-nav.other-navbar > li .chat-info i {
    color: #2a74e3;
}

.light-header nav.navbar .navbar-nav.other-navbar > li .btn-chat {
    background-color: #2a74e3;
}

.light-header nav.navbar .navbar-nav.other-navbar > li .btn-chat i {
    color: #ffffff;
}

.light-header nav.navbar .navbar-nav.other-navbar > li .btn-chat:hover, .light-header nav.navbar .navbar-nav.other-navbar > li .btn-chat:focus {
    background-color: #1c67d7;
}