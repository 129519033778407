/* ----------------------------------
    3 - Top Content Styles
-----------------------------------*/
#top-content {
    padding-top: 35px;
    padding-bottom: 35px;
}

#top-content .page-title {
    font-size: 23px;
    font-weight: 700;
    color: #ffffff;
}

#top-content .page-title .title-with-icon {
    display: block;
    width: 100%;
    text-decoration: none;
    margin-bottom: 7px;
}

#top-content .page-title .title-with-icon .icon {
    width: 60px;
    height: 60px;
    background-color: #5993fa;
    display: inline-block;
    border-radius: 36px;
    text-align: center;
    vertical-align: middle;
    margin-right: 24px;
    padding-top: 10px;
}

#top-content .page-title .title-with-icon .icon img {
    width: 33px;
    -webkit-filter: grayscale(100%) brightness(130%);
    -moz-filter: grayscale(100%) brightness(130%);
    -ms-filter: grayscale(100%) brightness(130%);
    filter: grayscale(100%) brightness(130%);
}

#top-content .page-title .title-with-icon .text {
    font-size: 23px;
    font-weight: 700;
    color: #ffffff;
}

#top-content .page-subtitle {
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    margin-top: 10px;
}

.light-header #top-content .page-title {
    color: #2A74E3;
}

.light-header #top-content .page-title .title-with-icon .icon {
    background-color: #5993fa;
}

.light-header #top-content .page-title .title-with-icon .text {
    color: #2A74E3;
}

.light-header #top-content .page-subtitle {
    color: #2A74E3;
}

.light-header #top-content form .domain-input {
    font-size: 18px;
    font-weight: 700;
    color: #3D5E98;
    background-color: #fff;
    border: 0;
    border-radius: 50px;
    padding: 12px 32px;
    height: inherit;
    outline: none;
    -webkit-box-shadow: 4px 12px 18px rgba(31, 27, 90, 0.1);
    box-shadow: 4px 12px 18px rgba(31, 27, 90, 0.1);
}

.light-header #top-content form .hbtn.search {
    font-size: 18px;
    font-weight: 600;
    padding: 12px 32px;
    margin-left: 24px;
}

#header-holder.innerpage.light-bg #top-content .page-title .title-with-icon .icon {
    background-color: #ffc600;
}

#header-holder.innerpage.light-bg #top-content .page-title .title-with-icon .icon img {
    width: 38px;
    -webkit-filter: grayscale(0%) brightness(100%);
    -moz-filter: grayscale(0%) brightness(100%);
    -ms-filter: grayscale(0%) brightness(100%);
    filter: grayscale(0%) brightness(100%);
}

.innerpage #top-content {
    padding-top: 55px;
    padding-bottom: 80px;
    text-align: center;
}

.innerpage #top-content.st-on-top {
    padding-bottom: 200px;
}

.innerpage #top-content.less-padding-bottom {
    padding-bottom: 35px;
}

.main-slider .slide {
    padding-top: 160px;
    padding-bottom: 80px;
}

.main-slider .slide > .row {
    margin-left: 0;
    margin-right: 0;
}

.main-slider .slide > .row [class^="col-"] {
    padding-left: 0;
    padding-right: 0;
}

.main-slider .b-text {
    font-size: 65px;
    font-weight: 700;
    color: #ffffff;
    line-height: 64px;
    letter-spacing: 2px;
    margin-bottom: 35px;
}

.main-slider .m-text {
    font-size: 23px;
    font-weight: 300;
    color: #ffffff;
    margin-bottom: 55px;
}

.main-slider .m-text .bold {
    font-weight: 700;
}

.main-slider .img-holder {
    text-align: center;
}

.main-slider .img-holder img {
    display: inline-block;
    width: 100%;
    max-width: 500px;
    margin-top: -70px;
}

.main-slider .slick-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.main-slider .slick-dots li {
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
}

.main-slider .slick-dots li button {
    border: 0;
    background: #ffc601;
    text-indent: -9999px;
    overflow: hidden;
    width: 8px;
    height: 8px;
    font-size: 0;
    padding: 0;
    border-radius: 8px;
    cursor: pointer;
    outline: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.main-slider .slick-dots li.slick-active button {
    width: 60px;
    background: #ffffff;
}

.light-header .main-slider .slide .b-text {
    font-size: 65px;
    color: #2A74E3;
    margin-top: -90px;
}

.light-header .main-slider .slide .m-text {
    color: #333C4E;
}

.light-header .main-slider .slide .m-text .bold {
    color: #4E8BE6;
}

.light-header .main-slider .slick-dots li button {
    background: #2A74E3;
}

.light-header .main-slider .slick-dots li.slick-active button {
    background: #ffffff;
}

.main-domain-search-holder {
    text-align: center;
}

.main-domain-search-holder .b-text {
    margin-bottom: 70px;
}

.main-domain-search-holder form {
    display: inline-block;
    width: 80%;
    max-width: 720px;
    margin-bottom: 20px;
}

.main-domain-search-holder .input-items {
    position: relative;
    width: 100%;
}

.main-domain-search-holder .input-items .input-items-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.main-domain-search-holder .form-control.domain-input {
    font-size: 22px;
    font-weight: 300;
    color: #111a2b;
    border-radius: 50px;
    border: 3px solid #5993fa;
    padding: 13px 32px;
    height: inherit;
    background-color: #fff;
    outline: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.main-domain-search-holder .form-control.domain-input:hover, .main-domain-search-holder .form-control.domain-input:focus {
    background-color: #ececec;
}

.main-domain-search-holder .btn {
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px;
    padding: 6px 27px;
    outline: none !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.main-domain-search-holder .btn.search {
    color: #111a2b;
    background-color: #ffc600;
}

.main-domain-search-holder .btn.search:hover, .main-domain-search-holder .btn.search:focus {
    background-color: #f0ba00;
}

.main-domain-search-holder .btn.transfer {
    color: #fff;
    background-color: #5993fa;
}

.main-domain-search-holder .btn.transfer:hover, .main-domain-search-holder .btn.transfer:focus {
    background-color: #4083f9;
}
