@charset "UTF-8";

@font-face {
  font-family: "hustbee";
  src:url("hustbee.eot");
  src:url("hustbee.eot?#iefix") format("embedded-opentype"),
    url("hustbee.woff") format("woff"),
    url("hustbee.ttf") format("truetype"),
    url("hustbee.svg#hustbee") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "hustbee" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="hstb-"]:before,
[class*=" hstb-"]:before {
  font-family: "hustbee" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hstb{
  display: inline-block;
  font-family: "hustbee" !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-size-adjust: none;
  font-stretch: normal;
  font-feature-settings: normal;
  font-language-override: normal;
  font-kerning: auto;
  font-synthesis: weight style;
  font-variant: normal;
  font-size: inherit;
  text-rendering: auto;
  -moz-osx-font-smoothing: grayscale;
}

.hstb-chat:before {
  content: "\61";
}
.hstb-down-arrow:before {
  content: "\62";
}
.hstb-right-arrow:before {
  content: "\63";
}
.hstb-tick:before {
  content: "\64";
}
.hstb-placeholder:before {
  content: "\65";
}
.hstb-speech:before {
  content: "\66";
}
.hstb-speech-bubble:before {
  content: "\67";
}
.hstb-user:before {
  content: "\68";
}
.hstb-info:before {
  content: "\6a";
}
.hstb-lock:before {
  content: "\6b";
}
.hstb-settings:before {
  content: "\6c";
}
.hstb-two-circling-arrows:before {
  content: "\6d";
}