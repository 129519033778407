/*------------------------------------------------------------------
	18 - Footer Styles
-------------------------------------------------------------------*/
.footer {
    position: relative;
    padding-top: 80px;
    padding-bottom: 22px;
    background-color: #111a2b;
}

.btn-go-top {
    display: inline-block;
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    background-color: #ffc600;
    text-align: center;
    border-radius: 100px;
    padding-top: 13px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn-go-top i {
    font-size: 20px;
    color: #111a2b;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.btn-go-top:hover, .btn-go-top:focus {
    top: -25px;
    background-color: #f2c523;
}

.footer-menu {
    margin-bottom: 50px;
}

.footer-menu h4 {
    font-size: 19px;
    font-weight: 700;
    color: #ffc600;
    margin-bottom: 40px;
}

.footer-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-menu ul li {
    font-size: 16px;
    color: #91a0bd;
    margin-bottom: 20px;
}

.footer-menu ul li a {
    position: relative;
    font-size: 16px;
    color: #91a0bd;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.footer-menu ul li a:before {
    position: absolute;
    display: inline-block;
    content: "";
    left: 1px;
    bottom: -1px;
    width: 0;
    height: 2px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.footer-menu ul li a:hover, .footer-menu ul li a:focus {
    color: #ffffff;
}

.footer-menu ul li a:hover:before, .footer-menu ul li a:focus:before {
    width: 10px;
}

.footer-menu ul.social li {
    display: inline-block;
    margin-right: 28px;
}

.footer-menu ul.social li a:before {
    display: none;
}

.footer-menu ul.social li a i {
    font-size: 22px;
}

.sub-footer {
    border-top: 1px solid #91a0bd;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sub-footer .sub-footer-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sub-footer .sub-footer-menu ul li {
    display: inline-block;
    margin-right: 20px;
}

.sub-footer .sub-footer-menu ul li:last-child {
    margin-right: 0;
}

.sub-footer .sub-footer-menu ul li a {
    font-size: 14px;
    color: #65728b;
}

.sub-footer .copyright {
    text-align: right;
    font-size: 14px;
    color: #65728b;
}