/* ----------------------------------
    4 - Features Section Styles
-----------------------------------*/
.features {
    background-color: #fbfbfb;
    padding-top: 50px;
    padding-bottom: 50px;
}

.features .def-aligned {
    text-align: right;
}

.features .feature-info {
    display: inline-block;
    padding: 60px 0;
    width: 100%;
    max-width: 470px;
    text-align: left;
}

.features .feature-info .feature-title {
    font-size: 33px;
    font-weight: 700;
    color: #333c4e;
    margin-bottom: 20px;
}

.features .feature-info .feature-text {
    font-size: 18px;
    font-weight: 300;
    color: #090a19;
    margin-bottom: 25px;
    width: 100%;
}

.features .img-holder {
    background-image: url("../../images/cloud-bg-sm.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    text-align: center;
    padding: 30px 0;
}

.features .img-holder img {
    width: 80%;
    max-width: 346px;
}
