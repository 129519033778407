/*------------------------------------------------------------------
	6 - Latest News Section Styles
-------------------------------------------------------------------*/
.latest-news {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #eaf2fe;
}

.latest-news .row-title {
    text-align: left;
    font-size: 27px;
    font-weight: 700;
    color: #4d5666;
    margin-bottom: 30px;
}

.latest-news .main-btn-holder {
    text-align: right;
    margin-bottom: 30px;
}

.latest-news .post-box {
    position: relative;
    border-radius: 15px;
    padding: 115px 35px 40px 35px;
    margin: 20px 0;
    z-index: 1;
}

.latest-news .post-box.blue-bg {
    background-color: #4789fc;
}

.latest-news .post-box.blue-bg .post-img {
    opacity: 0.45;
}

.latest-news .post-box.grey-bg {
    background-color: #333c4e;
}

.latest-news .post-box.grey-bg .post-img {
    opacity: 0.15;
}

.latest-news .post-box.yellow-bg {
    background-color: #ffc600;
}

.latest-news .post-box.yellow-bg .post-img {
    opacity: 0.11;
}

.latest-news .post-box .post-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    z-index: -1;
    background-size: 100%;
    background-position: center;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.latest-news .post-box .badge {
    margin-bottom: 8px;
}

.latest-news .post-box .post-title {
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    color: #111a2b;
    max-width: 70%;
    margin-bottom: 15px;
}

.latest-news .post-box .post-link a {
    font-size: 10.5px;
    font-weight: 600;
    padding: 10px 14px;
    color: #3e6077;
    background-color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.latest-news .post-box .post-link a:hover, .latest-news .post-box .post-link a.focus {
    color: #ffc600;
}

.latest-news .post-box:hover .post-img {
    background-size: 105%;
    -webkit-filter: opacity(80%);
    -moz-filter: opacity(80%);
    -ms-filter: opacity(80%);
    filter: opacity(80%);
}